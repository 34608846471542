/*!
 * fullPage 4.0.20
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2021 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
.fp-enabled body, html.fp-enabled {
  -webkit-tap-highlight-color: #0000;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.fp-section {
  box-sizing: border-box;
  height: 100%;
  display: block;
  position: relative;
}

.fp-slide {
  float: left;
}

.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block;
}

.fp-slides {
  z-index: 1;
  height: 100%;
  transition: all .3s ease-out;
  position: relative;
  overflow: hidden;
}

.fp-table {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
}

.fp-slidesContainer {
  float: left;
  position: relative;
}

.fp-controlArrow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  z-index: 4;
  cursor: pointer;
  margin-top: -38px;
  position: absolute;
  top: 50%;
  transform: translate3d(0, 0, 0);
}

.fp-prev {
  left: 15px;
}

.fp-next {
  right: 15px;
}

.fp-arrow {
  border-style: solid;
  width: 0;
  height: 0;
}

.fp-arrow.fp-prev {
  border-width: 38.5px 34px 38.5px 0;
  border-color: #0000 #fff #0000 #0000;
}

.fp-arrow.fp-next {
  border-width: 38.5px 0 38.5px 34px;
  border-color: #0000 #0000 #0000 #fff;
}

.fp-notransition {
  transition: none !important;
}

#fp-nav {
  z-index: 100;
  opacity: 1;
  transform: translateY(-50%);
  position: fixed;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
}

#fp-nav.fp-right {
  right: 17px;
}

#fp-nav.fp-left {
  left: 17px;
}

.fp-slidesNav {
  z-index: 4;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: translate3d(0, 0, 0);
  margin: 0 auto !important;
  left: 0 !important;
}

.fp-slidesNav.fp-bottom {
  bottom: 17px;
}

.fp-slidesNav.fp-top {
  top: 17px;
}

#fp-nav ul, .fp-slidesNav ul {
  margin: 0;
  padding: 0;
}

#fp-nav ul li, .fp-slidesNav ul li {
  width: 14px;
  height: 13px;
  margin: 7px;
  display: block;
  position: relative;
}

.fp-slidesNav ul li {
  display: inline-block;
}

#fp-nav ul li a, .fp-slidesNav ul li a {
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: block;
  position: relative;
}

#fp-nav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li a.active span, .fp-slidesNav ul li:hover a.active span {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  margin: -6px 0 0 -6px;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  z-index: 1;
  -o-transition: all .1s ease-in-out;
  background: #333;
  border: 0;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
}

#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}

#fp-nav ul li .fp-tooltip {
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  cursor: pointer;
  width: 0;
  max-width: 220px;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  display: block;
  position: absolute;
  top: -2px;
  overflow: hidden;
}

#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active + .fp-tooltip {
  opacity: 1;
  width: auto;
  transition: opacity .2s ease-in;
}

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px;
}

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px;
}

.fp-auto-height .fp-slide, .fp-auto-height.fp-section, .fp-responsive .fp-is-overflow.fp-section {
  height: auto !important;
}

.fp-scrollable .fp-section, .fp-scrollable .fp-slide, .fp-scrollable.fp-responsive .fp-is-overflow.fp-section {
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
}

.fp-scrollable .fp-section:not(.fp-auto-height):not([data-percentage]), .fp-scrollable .fp-slide:not(.fp-auto-height):not([data-percentage]), .fp-scrollable.fp-responsive .fp-is-overflow.fp-section:not(.fp-auto-height):not([data-percentage]) {
  min-height: calc(var(--vh, 1vh) * 100);
  min-height: 100vh;
}

.fp-overflow {
  justify-content: flex-start;
  max-height: 100vh;
}

.fp-scrollable .fp-auto-height .fp-overflow {
  max-height: none;
}

.fp-is-overflow .fp-overflow.fp-auto-height, .fp-is-overflow .fp-overflow.fp-auto-height-responsive, .fp-is-overflow > .fp-overflow {
  overflow-y: auto;
}

.fp-overflow {
  outline: 0;
}

.fp-overflow.fp-table {
  display: block;
}

.fp-responsive .fp-auto-height-responsive .fp-slide, .fp-responsive .fp-auto-height-responsive.fp-section {
  height: auto !important;
  min-height: auto !important;
}

.fp-sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fp-scroll-mac .fp-overflow::-webkit-scrollbar {
  background-color: #0000;
  width: 9px;
}

.fp-scroll-mac .fp-overflow::-webkit-scrollbar-track {
  background-color: #0000;
}

.fp-scroll-mac .fp-overflow::-webkit-scrollbar-thumb {
  background-color: #0006;
  border: 4px solid #0000;
  border-radius: 16px;
}

.fp-warning, .fp-watermark {
  z-index: 9999999;
  position: absolute;
  bottom: 0;
}

.fp-warning, .fp-watermark a {
  color: #000;
  background: #fff9;
  border-radius: 3px;
  margin: 12px;
  padding: 5px 8px;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
}

.fp-noscroll .fp-overflow {
  overflow: hidden;
}
/*# sourceMappingURL=index.923ff515.css.map */
